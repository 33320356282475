<template>
  <h3>Краткий обзор функционала</h3>
  <div class="block">
    <h5>Глоссарий</h5>
    <div class="list">
      <p><b>Сущность</b> – комбинация параметров «Резервная группа», «Тип расчета», «Отчетная дата», «Валюта», для которой доступно создание расчетов.</p>
      <p><b>Версия данных</b> – данные, содержащиеся в загрузочном файле для конкретной сущности. Для одной сущности может быть несколько версий данных. Например, версия до исключения выбросов и версия после исключения выбросов.</p>
      <p><b>Метод расчета</b> – актуарный метод, доступный для оценки конкретного типа расчета. Например, метод цепной лестницы доступен для расчета резерва убытков, но не доступен для расчета резерва косвенных расходов на урегулирование убытков.</p>
      <p><b>Графический интерфейс</b> – программная оболочка, которая предоставляет пользователю возможность взаимодействовать с ПО. Она визуализирует многие компоненты в виде графических объектов, например, кнопки, меню, ячейки и т. д.</p>
      <p><b>Расчет</b> – математические вычисления, произведенные для оценки конкретной сущности на основе конкретной версии данных с применением конкретного метода расчета и сопровождающиеся созданием конкретного набора таблиц на интерфейсе.</p>
      <p><b>РУ</b> – Резерв Убытков</p>
      <p><b>Метод БФ</b> – метод «Борнхьюттера - Фергюссона»</p>
      <p><b>Метод ЦЛ</b> – метод «Цепная лестница»</p>
      <p><b>РУУ</b> – Расходы на Урегулирования убытков</p>
    </div>
  </div>
  <div class="block">
    <h5>Основные разделы</h5>
    <p>Основными разделами сайта являются:</p>
    <table>
      <tr>
        <th>Название раздела</th>
        <th>Назначение раздела</th>
      </tr>
      <tr>
        <td>Свод по расчётам</td>
        <td>
          <ul>
            <li>Отображение всех сущностей, для каждой из которых создан по меньшей мере один расчет. Для каждой сущности отображается дата создания первого расчёта, имя пользователя, загрузившего данные для первого расчёта, статус расчетов.</li>
            <li>Отображение статуса сущностей.</li>
            <li>Упрощённая навигация (например, в столбце «Тип расчёта» есть ссылки на расчеты для соответствующих сущностей)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Наилучшие оценки</td>
        <td>
          <ul>
            <li>Оценка резервов убытков и расходов на урегулирование и ожиданий по денежным потокам, связанным с убытками по прямому бизнесу и входящему перестрахованию</li>
            <li>Анализ данных (треугольников развития, коэффициентов развития и т.д.).</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Рисковые поправки</td>
        <td>
          <ul>
            <li>Оценка рисковых поправок на нефинансовый риск в части ООЧП и ОВТ по прямому бизнесу и входящему перестрахованию.</li>
            <li>Оценка рисковых поправок на нефинансовый риск в части ООЧП и ОВТ по прямому бизнесу и входящему перестрахованию.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Денежные потоки</td>
        <td>
          <ul>
            <li>Построение денежных потоков на основе произведённых оценок резервов и доли перестраховщиков в резервах.</li>
            <li>Оценка доли перестраховщиков в резервах и ожиданий по денежным потокам, связанным с возмещениями убытков от перестраховщиков.</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Перестрахование</td>
        <td>
          <ul>
            <li>Оценка доли перестраховщиков в резервах и ожиданий по денежным потокам, связанным с возмещениями убытков от перестраховщиков.</li>
            <li>Анализ данных (треугольников развития, коэффициентов развития и т.д.)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Обзор результата</td>
        <td>
          <ul>
            <li>Выбор итоговых оценок резервов, доли перестраховщиков в резервах и ожиданий по денежным потокам, 
              связанным с убытками и возмещениями убытков от перестраховщиков. 
              Например, может быть несколько расчётов резерва убытков на одну отчётную дату с использованием разных методов или разных коэффициентов развития, 
              для каждого периода пользователь может выбрать более точную оценку.</li>
          </ul>
        </td>
      </tr>
    </table>
  </div>

  <div class="block">
    <h5>Навигация</h5>
    <p>Навигация по разделам может осуществляться несколькими способами:</p>
    <ol>
      <li>
        <b>Навигация с помощью верхней панели</b>
        <p>На верхней панели представлено меню со ссылками на основные разделы. При нажатии на каждый пункт такого меню осуществляется переход в соответствующий раздел. Все фильтры при этом принимают значения по умолчанию.</p>
        <p>Под фильтрами на верхней панели перечислены доступные действия пользователя, часть из которых связана с навигацией по разделам. Таким способом можно перемещаться между разделами без «сбрасывания» значений фильтров до значений по умолчанию, т.е. без необходимости повторно отфильтровывать необходимые расчёты.</p>
        <p>Дополнительно с помощью верхней панели можно перейти в личный кабинет пользователя, раздел настроек, на главную страницу или на предыдущую страницу.</p>
      </li>
      <li>
        <b>Навигация с помощью левой панели</b>
        <p>На верхней панели представлено меню со ссылками на основные разделы. При нажатии на каждый пункт такого меню осуществляется переход в соответствующий раздел. Все фильтры при этом принимают значения по умолчанию.</p>
        <p>Левая панель также имеет дополнительный функционал в некоторых разделах:</p>
        <ul>
          <li>В разделах «Наилучшие оценки», «Рисковые поправки» и «Перестрахование» с помощью левой панели можно создавать расчёты и осуществлять навигацию по созданным ранее расчётам. Данный функционал появляется при условии, что с помощью фильтров на интерфейсе отображена конкретная сущность.</li>
          <li>В разделе «Денежные потоки» с помощью левой панели можно осуществлять навигацию по видам денежных потоков в рамках конкретной сущности.</li>
        </ul>
      </li>
      <li>
        <b>В разделе «Денежные потоки» с помощью левой панели можно осуществлять навигацию по видам денежных потоков в рамках конкретной сущности.</b>
        <p>В разделе «Свод по расчётам» перечислены все сущности, для которых есть хотя бы один расчёт в хотя бы одном из разделов: «Наилучшие оценки», «Рисковые поправки», «Перестрахование» и «Денежные потоки», при этом сущности сгруппированы по принадлежности к перечисленным разделам. По клику на ссылку в столбце «Тип расчета» и строке с конкретной сущностью пользователь попадает на страницу с расчётами для соответствующей сущности.</p>
      </li>
    </ol>
  </div>

  <div class="block">
    <h5>Ролевая модель</h5>
    <p>Допустимо создание пользователей со следующими наборами прав (ролями):</p>
    <table>
      <tr>
        <th>Наименование роли</th>
        <th>Доступные права</th>
      </tr>
      <tr>
        <td>Аудитор</td>
        <td>
          <ul>
            <li>Просмотр всех страниц в режиме чтения, при этом все кнопки, кроме кнопок для навигации, являются не активными</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Актуарий</td>
        <td>
          <ul>
            <li>Просмотр всех страниц в режиме чтения</li>
            <li>Просмотр страниц с загрузкой данных, расчётами в статусе «Черновик» и страниц с обзором результата в режиме редактирования.</li>
            <li>Отправка расчёта на проверку и утверждение расчёта.</li>
            <li>Все кнопки для расчётов в статусе «Черновик» активны, все кнопки для расчётов в статусе «На утверждении» или «Утверждён», кроме кнопок для навигации, являются не активными</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Главный актуарий</td>
        <td>
          <ul>
            <li>Просмотр всех страниц в режиме чтения</li>
            <li>Просмотр страниц с загрузкой данных, расчётами в статусе «Черновик»/ «На утверждении» и страниц с обзором результата в режиме редактирования.</li>
            <li>Утверждение или отклонение расчёта, который был утвержден актуарием или главным актуарием.</li>
            <li>Разблокировка расчёта в статусе «Утверждён» для дальнейшего редактированияи</li>
            <li>Все кнопки для расчётов в статусе «Черновик»/ «На утверждении» активны, все кнопки для расчётов в статусе «Утверждён», кроме кнопок для навигации и кнопки «Разблокировать», являются не активными</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Администратор</td>
        <td>
          <ul>
            <li>Все права, доступные Главному актуарию.</li>
            <li>Создание новых пользователей.</li>
          </ul>
        </td>
      </tr>
    </table>
    <p>ПО допускает одновременную авторизацию пользователя на неограниченном числе устройств.</p>
  </div>

  <div class="block">
    <h5>Ролевая модель</h5>
    <p>Допустимо создание пользователей со следующими наборами прав (ролями):</p>
    <img src="@/assets/img/guide/roles.png" alt="" srcset="">
    <p>При этом следует учитывать связь между статусом расчёта, ролью пользователя и допустимыми действиями.</p>
  </div>

  <div class="block">
    <h5>Статусы расчётов, связи между сущностями</h5>
    <p>В ПО предусмотрена система иерархии между видами расчётов, влияние которой на статус конкретного расчёта может заключаться в следующем:</p>
    <ul>
      <li>Если происходят изменения/обновления в расчётах, которые согласно иерархии являются дочерними – у их родительского расчёта к статусу добавляется приписка «данные были обновлены» или «требуется пересчет» в зависимости от конкретной ситуации.</li>
      <li>Дочерний расчёт нельзя утвердить/передать на утверждение раньше родительского расчета.</li>
      <li>Дочерний расчёт нельзя утвердить/передать на утверждение раньше родительского расчета.</li>
      <li>Если добавляется новый дочерний расчёт – в родительском расчёте может измениться размерность отдельных таблиц и перечень опций в выпадающих списках.</li>
    </ul>
    <p>У каждого расчёта может одновременно быть лишь один из следующих статусов:</p>
    <table>
      <tr>
        <th>Статус</th>
        <th>Комментарий</th>
      </tr>
      <tr>
        <td>Черновик</td>
        <td>Расчёт доступен для редактирования всем, кроме аудитора.</td>
      </tr>
      <tr>
        <td>Черновик (данные были обновлены)</td>
        <td>Расчёт доступен для редактирования всем, кроме аудитора, при этом были загружены новые данные по рассматриваемой сущности или по сущностям, от которых она зависит</td>
      </tr>
      <tr>
        <td>Черновик (требуется пересчет)</td>
        <td>Расчёт доступен для редактирования всем, кроме аудитора, при этом были обновлены расчёты, от которых зависит рассматриваемый расчёт. Расчёт нельзя передать на утверждение или утвердить, пока он не будет пересчитан.</td>
      </tr>
      <tr>
        <td>На утверждении</td>
        <td>Расчёт находится на рассмотрении у всех пользователей с ролями главного актуария или администратора. Пользователи с ролью актуарий не могут редактировать рассматриваемый расчет.</td>
      </tr>
      <tr>
        <td>На утверждении (данные были обновлены)</td>
        <td>Расчёт находится на рассмотрении у всех пользователей с ролями главного актуария или администратора. Пользователи с ролью актуарий не могут редактировать рассматриваемый расчет. При этом были загружены новые данные по рассматриваемой сущности или по сущностям, от которых она зависит.</td>
      </tr>
      <tr>
        <td>Утвержден</td>
        <td>Расчёт утверждён одним из пользователей с ролью главного актуария или администратора. Никто не может редактировать расчёты с таким статусом. Другой пользователь с ролью главного актуария или администратора может отклонить утверждённый расчёт, т.е. вернуть возможность редактирования расчёта</td>
      </tr>
    </table>
    <p>Действия пользователей, приводящие к изменению статусов расчётов отражены в таблице ниже. Под терминами «Актуарий», «Главный актуарий», «Администратор» подразумеваются любые пользователи, имеющие соответствующую роль. Роль «Аудитор» не обладает правами изменения статусов расчётов.</p>
    <table>
      <tr>
        <th></th>
        <th colspan="6">Статус, в который переходит расчёт после соответствующих действий</th>
      </tr>
      <tr>
        <td>Исходный статус расчёта</td>
        <td>Черновик</td>
        <td>Черновик (данные были обновлены)</td>
        <td>Черновик (требуется пересчет)</td>
        <td>На утверждении</td>
        <td>На утверждении (данные были обновлены)</td>
        <td>Утвержден</td>
      </tr>
      <tr>
        <td>Черновик</td>
        <td>Актуарий/ Главный актуарий/ Администратор меняет расчет, создает новые расчеты</td>
        <td>Актуарий/ Главный актуарий/ Администратор загрузил новые данные для этой сущности или для сущностей, от которых зависит расчёт</td>
        <td>Актуарий/ Главный актуарий/ Администратор изменил один из расчётов, от которых зависит текущий расчёт</td>
        <td>Актуарий в разделе «Свод по расчетам» нажал кнопку «Отправить на проверку»</td>
        <td></td>
        <td>Главный актуарий/ Администратор в разделе «Свод по расчетам» нажал кнопку «Утвердить»</td>
      </tr>
      <tr>
        <td>Черновик (данные были обновлены)</td>
        <td>Актуарий/ Главный актуарий/ Администратор открыл расчёт и произвел действия со всплывшим диалоговым окном</td>
        <td></td>
        <td>Актуарий/ Главный актуарий/ Администратор изменил один из расчётов, от которых зависит текущий расчёт</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Черновик (требуется пересчет)</td>
        <td>Актуарий/ Главный актуарий/ Администратор открыл расчёт и нажал «Рассчитать»</td>
        <td>Актуарий/ Главный актуарий/ Администратор загрузил новые данные для этой сущности или для сущностей, от которых зависит расчёт</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>На утверждении</td>
        <td>Главный актуарий/ Администратор в разделе «Свод по расчетам» нажал кнопку «Отклонить утверждение»</td>
        <td></td>
        <td></td>
        <td>Главный актуарий/ Администратор меняет расчет, создает новые расчеты</td>
        <td>Главный актуарий/ Администратор загрузил новые данные для этой сущности или для сущностей, от которых зависит расчёт</td>
        <td>Главный актуарий/ Администратор в разделе «Свод по расчетам» нажал кнопку «Утвердить»</td>
      </tr>
      <tr>
        <td>На утверждении (данные были обновлены)</td>
        <td>Главный актуарий/ Администратор в разделе «Свод по расчетам» нажал кнопку «Отклонить утверждение»</td>
        <td></td>
        <td></td>
        <td>Главный актуарий/ Администратор меняет расчет, создает новые расчеты</td>
        <td></td>
        <td>Главный актуарий/ Администратор в разделе «Свод по расчетам» нажал кнопку «Утвердить»</td>
      </tr>
      <tr>
        <td>Утвержден</td>
        <td></td>
        <td></td>
        <td></td>
        <td>Главный актуарий/ Администратор в разделе «Свод по расчетам» нажал кнопку «Разблокировать»</td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>
  
  <div class="block">
    <h5>Совместное использование. Режим параллельной работы</h5>
    <p>В ПО «Actool» нет ограничения не количество пользователей, которые могут параллельно пользоваться ПО.</p>
  </div>

  <div class="block">
    <h5>Добавление и удаление портфелей</h5>
    <p>Предусмотрены две резервные группы: КАСКО и ОСАГО. Также есть возможность создавать свои портфели/резервные группы.</p>
    <p>Для этого нужно нажать на значок шестерёнки рядом с именем пользователя и перейти в настройки системы. Затем нужно нажать на кнопку «Добавить резервную группу», ввести название группы и нажать «Добавить». </p>
    <p>Также, пользователь может удалять портфели, нажав на кнопку «Удалить» рядом с резервной группой.</p>
    <img src="@/assets/img/guide/group.png" alt="" srcset="">
  </div>

  <div class="block">
    <h5>Загрузка файлов</h5>
    <p>Загрузка файлов осуществляется посредством передачи в ПО excel-файлов определённой формы. К загрузочным файлам предъявляются требования в части:</p>
    <ul>
      <li>Количества листов и их наименования</li>
      <li>Размерности таблиц на листах</li>
      <li>Типа параметров, которые передаются (целочисленные, выбор из ограниченного списка, даты, текст и т.д.)</li>
    </ul>
    <p>При загрузке важно правильно заполнить шапку в каждом листе, которую пользователь найдет в шаблонах загрузочных файлов. Зеленым указаны поля для заполнения. Белые и серые поля не требуют модификации.</p>
    <p>При загрузке важно правильно указать поле «Тип данных» в соответствие с шаблонами для загрузки. Данное поле идентифицирует расчеты в листе.</p>
    <img src="@/assets/img/guide/template.png" width="400px" alt="" srcset="">
    <p>Для загрузки требуется перейти по ссылке «Загрузить данные» из раздела «Свод по расчётам» (ссылка находится на верхней панели под фильтрами).</p>
    <img src="@/assets/img/guide/reports.png" width="400px" alt="" srcset="">
    <p>Далее требуется:</p>
    <ul>
      <li>выбрать из выпадающего списка резервную группу (список опций задаётся администратором)</li>
    </ul>
    <img src="@/assets/img/guide/group-form.png" width="400px" alt="" srcset="">
    <ul>
      <li>выбрать из выпадающего списка тип расчета (список опций фиксирован)</li>
    </ul>
    <img src="@/assets/img/guide/upload-type.png" width="400px" alt="" srcset="">
    <ul>
      <li>указать версию данных (см. раздел «Версии данных» текущей инструкции) – строковое значение;</li>
    </ul>
    <img src="@/assets/img/guide/upload-version.png" width="400px" alt="" srcset="">
    <ul>
      <li>нажать на кнопку «Choose file», после выбора файла – его название выводится справа от кнопки для удобства пользователя;</li>
    </ul>
    <img src="@/assets/img/guide/upload-file.png" width="400px" alt="" srcset="">
    <ul>
      <li>нажать на кнопку «Загрузить данные».</li>
    </ul>
    <img src="@/assets/img/guide/upload-button.png" width="200" alt="" srcset="">
    <p>Если загрузочный файл составлен в соответствии с требованиям системы, описанными выше в настоящем разделе, выбранным значениям в выпадающих списках и информации, уже содержащейся в базе данных, после проведения загрузки произойдёт создание шаблонного расчёта, и пользователь вернётся в раздел «Свод по расчётам».</p>
  </div>

  <div class="block">
    <h5>Мультизагрузка</h5>
    <p>Также пользователь может загрузить файлы с помощью мультизагрузки. В данном случае требуется указать правильные названия файлов для корректной работы функционала.</p>
    <p>Файл загрузки должен быть назван по следующему принципу:</p>
    <p><b>ID группы_ID загрузки_отчетная дата_версия данных</b></p>
    <p>ID загрузки указывается в соответствии с таблицей ниже:</p>
    <table>
      <tr>
        <th>Тип загрузки</th>
        <th>ID загрузки</th>
      </tr>
      <tr>
        <td>ДП по ОВТ</td>
        <td>103</td>
      </tr>
      <tr>
        <td>ДП по ООЧП</td>
        <td>104</td>
      </tr>
      <tr>
        <td>Загрузка для НО по РРУУ прямые</td>
        <td>106</td>
      </tr>
      <tr>
        <td>Загрузка для НО по РРУУ прочие</td>
        <td>107</td>
      </tr>
      <tr>
        <td>Загрузка для НО по РРУУ судебные</td>
        <td>108</td>
      </tr>
      <tr>
        <td>Загрузка для НО по ГОТС</td>
        <td>109</td>
      </tr>
      <tr>
        <td>Загрузка для НО по Регрессам</td>
        <td>110</td>
      </tr>
      <tr>
        <td>Загрузка для НО по Нулевым требованиям</td>
        <td>112</td>
      </tr>
      <tr>
        <td>Загрузка для НО по Разницам от РСА</td>
        <td>113</td>
      </tr>
      <tr>
        <td>РП на ООЧП</td>
        <td>114</td>
      </tr>
      <tr>
        <td>ДП по ООЧП (мес)</td>
        <td>116</td>
      </tr>
      <tr>
        <td>ДП по ООЧП (исходящее)</td>
        <td>127</td>
      </tr>
      <tr>
        <td>ДП по ООЧП (мес исходящее)</td>
        <td>129</td>
      </tr>
      <tr>
        <td>ДП по ОВТ (мес)</td>
        <td>117</td>
      </tr>
      <tr>
        <td>ДП по ОВТ (исходящее)</td>
        <td>128</td>
      </tr>
      <tr>
        <td>ДП по ОВТ (мес исходящее)</td>
        <td>130</td>
      </tr>
      <tr>
        <td>Прочие потоки</td>
        <td>118</td>
      </tr>
      <tr>
        <td>Прочие потоки (мес)</td>
        <td>119</td>
      </tr>
      <tr>
        <td>Перестрахование</td>
        <td>120</td>
      </tr>
      <tr>
        <td>Прочие потоки исходящее</td>
        <td>131</td>
      </tr>
      <tr>
        <td>Прочие потоки исходящее (мес)</td>
        <td>132</td>
      </tr>
    </table>
    <p><b>ID группы</b> – это идентификатор резервной группы. Необязательно то же самое название резервной группы, что и в ПО Actool, данный идентификатор нужен исключительно для идентификации файлов при загрузке (резервная группа будет считана на основе шапки в загрузочном файле). Оно может быть таким же, как в ПО Actool, если в нем отсутствуют пробелы или дополнительные символы.</p>
    <p>ID группы может содержать:</p>
    <ol>
      <li>Слово или сочетание буквенных символов на английском языке (например – DMS)</li>
      <li>Слово или сочетание буквенных символов на английском языке (например – DMS)</li>
      <li>Набор цифровых символов (например – 123)</li>
    </ol>
    <p>При этом, ID группы не может содержать <u>пробелы или дополнительные символы.</u></p>
    <p><b>Отчетная дата</b> в названии файла указывается в формате «ДД.ММ.ГГ». Например: 31.12.23, 31.12.22 и т.д.</p>
    <p>Ниже приведены примеры названий для  файла Загрузки для РУ на 31.12.23 версии данных 1 по группе ДМС:</p>
    <ol>
      <li>ДМС_101_31.12.23_1</li>
      <li>DMS_101_31.12.23_1</li>
      <li>123_101_31.12.23_1</li>
    </ol>
  </div>

  <div class="block">
    <h5>Последовательность загрузки</h5>
    <p>При начале работы с Actool, первым файлом нужно загрузить «Резерв убытков (РУ)». Все последующие сущности должны совпадать с отчетной датой и резервной группой, которые были указаны в РУ, иначе расчеты не сформируются</p>
    <p><i>Например, для загрузки Наилучших оценок по Нулевым требованиям по ОСАГО от 31 декабря 2023 года, нам сначала надо загрузить Резервы убытков по ОСАГО от 31 декабря 2023 года.</i></p>
  </div>

  <div class="block">
    <h5>Версии данных</h5>
    <p>В ПО предусмотрено версионирование данных в рамках каждой сущности. При загрузке данных пользователь указывает значение в виде строки, которое становится идентификатором загружаемой версии данных. Например, пользователь может загрузить несколько файлов для сущности:</p>
    <p>Резервная группа = КАСКО, Тип расчета = Резерв убытков, Отчётная дата = 31.12.20ХХ, Валюта = RUB</p>
    <p>Допустим, данные отличаются между собой количеством периодов в треугольнике развития убытков. Если пользователь отфильтрует в разделе «Наилучшие оценки» упомянутую сущность, у него будет возможность создать отдельные расчёты для каждой версии данных и сравнить полученные оценки резерва убытков.</p>
    <p>Значение версии данных должно быть уникальным в рамках одной сущности. Иными словами, допустима загрузка версий данных с одинаковыми названиями для разных сущностей. Например, пользователь на каждую из отчётных дат 31.12.20ХХ и 31.12.20YY загружает 2 версии данных: «треугольник до исключения выбросов» и «треугольник после исключения выбросов» - это не нарушает условие уникальности версий в рамках одной сущности, потому что для одной даты загружены два файла с уникальными идентификаторами версии данных, и для другой даты – два файла с уникальными идентификаторами версии данных.</p>
  </div>

  <div class="block">
    <h5>Проведение расчетов</h5>
    <p>Для проведения любых расчетов в ПО нужно нажать на кнопку «Рассчитать». При персонализации расчетов (изменение данных на интерфейсе), кнопка «Рассчитать» будет загораться красным, сигнализируя о необходимости заново провести расчет.</p>
  </div>
</template>
  
<script>
export default {
  name: 'QuickGuide',
}
</script>

<style scoped>
h3{
  font-size: 32px;
}

h5{
  margin: 20px 0px;
  font-size: 24px;
  color: #0074b5;
}

.list{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list p{
  font-size: 16px;
}

table, th, td {
  color: #6b6f77;
  border: 1px solid black;
  border-collapse: collapse;

  margin: 20px 0px;
}

th{
  padding: 0px 10px;
}

td:nth-child(1){
  padding: 10px;
}

td:nth-child(2){
  padding: 10px 10px 10px 30px;
}

ul{
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin: 20px 20px 20px 50px;
}

td ul{
  margin-left: unset;
}

ol{
  margin-left: 50px;
}

ol li{
  margin-top: 10px;
}

li p{
  margin-top: 10px;
}

ol li ul{
  margin-left: 50px;
}

p{
  margin-top: 10px;
  line-height: 20px;
}
</style>
