<template>
  <div class="multiselect">
    <div class="selected" @click='switchChoicesListState'>
      <template v-if="selected.length > 0">
        Выбрано {{ selected.length }}
      </template>
      <template v-else>
        {{ messageOnEmpty }}
      </template>
      <div class="arrow" >
        <svg fill="#000000" height="10px" width="10px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
          viewBox="0 0 330 330" xml:space="preserve">
        <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
          c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
          s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
        </svg>
      </div>
    </div>
    <div class="choices" v-if="choicesListOpen">
      <div class="choice">
        <input type="checkbox" @change="selectAll" :checked='selected.length === choices.length' id=""/>
        <label for='all'>Все</label>
      </div>
      <div v-for="(choice, index) in choices" :key="index" class="choice"> 
        <input type="checkbox" :value='choice' v-model="selected" />
        <label :for='index'>{{ choice }} </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Multiselect',
  props: {
    choices: Array,
    messageOnEmpty: String,
  },
  computed: {
    nonSelectedChoices(){
      return this.choices.filter(choice => !this.selected.includes(choice))
    }
  },
  data() {
    return {
      selected: [],
      choicesListOpen: false,
    }
  },
  mounted(){
    this.selected.push(this.choices[0])
  },
  watch: {
    selected: {
      handler(data) {
        this.$emit('onChange', data)
      },
      deep: true
    }
  },
  methods: {
    switchChoicesListState(){
      this.choicesListOpen = !this.choicesListOpen
    },
    onSelect(choice){
      if(this.selected.includes(choice)){
        this.removeFromSelected(choice)
      }else{
        this.selected.push(choice)
      }
    },
    removeFromSelected(choice){
      this.selected = this.selected.filter(item => item !== choice)

    },
    selectAll(){
      if (this.selected.length === this.choices.length) {
        this.selected = [this.choices[0]]
      } else {
        this.selected = this.choices
      }
    }
  }
}
</script>

<style scoped>
.multiselect{
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 300px;
  width: fit-content;
}

.selected{
  max-width: 290px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  background-color: white;
  min-height: 20px;
  padding-left: 5px;
  cursor: pointer;
}

.arrow{
  position: absolute;
  right: 10px;

}

.choices{
  position: absolute;
  top: 20px;
  width: 100%;
  background-color: white;
  width: calc(100% - 10px);

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
  border: 1px solid #8a8a8a;
}

.selected{
  border: 1px solid #8a8a8a;
}

.choice{
  display: flex;
  align-items: center;
  gap: 10px;
}

.choice input{
  cursor: pointer;
}
</style>
  