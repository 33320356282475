<template>
  <div class="section">
    <slot></slot>
  </div>
</template>
    
<script>
export default {
  name: 'GuideSection',
}
</script>
  
<style scoped>
.section{
  width: 80%;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1rem;

  background-color: white;
  border: 1px solid black;

  overflow: auto;

  padding-bottom: 100px;
}
</style>
