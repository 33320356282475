<template>
  <div :class="`${matrix.selection > 1 ? 'blue' : ''} ${matrix.report_id ? 'res-matrix-' + matrix.order : ''} ${(type == 129 && matrix.title === null || type == 116 && matrix.title === null) ? 'matrix-small ' : ''}matrix`">
    <table test-id="matrix_table">
      <thead v-if="matrix.column_set.length">
      <tr>
        <th :class="width" v-for="col in matrix.column_set.filter(c => c.line === 1)" :key="col.order"
            :rowspan="col.rowspan" :colspan="col.colspan">
          {{ col.name }}
        </th>
      </tr>
      <tr>
        <th :class="width" v-for="col in matrix.column_set.filter(c => c.line === 2)" :key="col.order"
            :colspan="col.colspan" @click.stop="columnClick(col)">
          {{ col.name }}
        </th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="row in matrix.row_set" :key="row.order" class="data-row">
        <td :class="`period ${row.is_bold ? 'bold' : ''}`" @click.stop="rowClick(row)">
          {{ row.name }}
        </td>
        <template v-for="col in Array(matrix.column_count).keys()" :key="col">
          <td v-for="cell in [getCell(row.order, col + 1)]" :key="col" @click.stop="cellClick(row, col + 1)"
              :class="`${row.is_bold ? 'bold' : ''} ${cell?.is_selected ? 'selected' : ''} ${cell?.is_editable ? 'editable' : ''} slot-${cell?.slot} ${width}`">
            <input v-if="canModifyMatrix && cell?.is_editable" type="text"
                   :value="getValue(row.order, col + 1)" @change.stop="changeValue($event.target, row.order, col + 1)">
            <span v-else>{{ getValue(row.order, col + 1) }}</span>
          </td>
        </template>
      </tr>
      </tbody>
    </table>

    <div v-if="type === 105 && matrix.order === 4" class="charts">
      <LineChart title="1-ый коэффициент развития" :display-y="false" :data="chartData[0]"/>
      <LineChart title="2-ой коэффициент развития" :display-y="false" :data="chartData[1]"/>
      <LineChart title="3-ий коэффициент развития" :display-y="false" :data="chartData[2]"/>
    </div>
    <template v-if="type === 105 && matrix.order === 9">
      <div class="charts">
        <LineChart title="Развитие коэффициентов выплат / ЗП, %" :display-x="false" :data="chartData[3]"/>
      </div>
      <div class="charts">
        <LineChart title="Убыточность по выплатам спустя 1 период, %" :display-y="false" :data="chartData[4]"/>
        <LineChart title="Убыточность по выплатам спустя 2 периода, %" :display-y="false" :data="chartData[5]"/>
        <LineChart title="Убыточность по выплатам спустя 3 периода, %" :display-y="false" :data="chartData[6]"/>
      </div>
    </template>
    <div v-if="type === 105 && matrix.order === 10" class="charts">
      <LineChart title="Развитие коэффициентов выплат / понесенный убыток, %" :display-x="false" :data="chartData[7]"/>
    </div>

      <div class="loading" v-show="loading">
    <img src="@/assets/img/loading.svg" alt="" srcset="">
  </div>
  </div>

</template>


<script>
import LineChart from "@/components/Report/LineChart.vue";

export default {
  name: 'Matrix',
  components: {LineChart},
  props: {
    type: Number,
    matrix: Object,
    chartData: Object,
  },
  inject: ['children_status', 'divider', 'reloadCells', 'canModify', 'lockStatus'],
  data() {
    return {
      cells: {},
      loading: false
    }
  },
  computed: {
    canModifyMatrix() {
      if (this.type === 199 && this.matrix.order > 1) {
        const status = this.children_status[this.matrix.associated_report_type]
        return this.lockStatus?.locked === true &&
            ([101, 102, 103].includes(status) && this.hasPerm('draft')) ||
            ([201, 202, 203].includes(status) && this.hasPerm('pending_approval'))
      }
      return this.canModify
    },
    width() {
      return ' width-' + this.divider
    }
  },
  emits: ['reloadMatrixCells'],
  watch: {
    reloadCells(value) {
      if (value.ids.length === 0 || value.ids.includes(this.matrix.id)) {
        this.loadCells()
      }
    }
  },
  mounted() {
    this.loadCells()
  },
  methods: {
    hasPerm(permission) {
      return window.drfUser.permissions.includes('api.' + permission)
    },
    loadCells() {
      this.$http
          .get(`/matrices/${this.matrix.parent_matrix_id || this.matrix.id}/cells`)
          .then(response => {
            if (this.matrix.is_heatmap) {
              const items = response.data
              const min = Math.min(...items.map(c => c.value)) - 0.01
              const max = Math.max(...items.map(c => c.value)) + 0.01
              const step = (max - min) / 7
              items.forEach(c => c.slot = Math.floor((c.value - min) / step))
            }

            this.cells = Object.assign({}, ...response.data.map(c => ({[c.row + '_' + c.column]: c})))
            console.log('Matrix->mounted(): cells are loaded')
          })
          .catch(error => {
            console.error('Matrix->mounted(): ERROR: ' + error)
          })
          .finally(()=>{this.loading = false})
    },
    getCell(row, col) {
      return this.cells[row + '_' + col]
    },
    getValue(row, col) {
      const cell = this.getCell(row, col)
      if (!cell) {
        return null
      }

      let value = cell.value
      let applied = false

      if ((this.type === 102 && this.matrix.order === 2 && row === 3) ||
          (this.type === 199 && this.matrix.order === 1 && col % 2 === 0) ||
          (this.type === 114 && this.matrix.order === 3 && col === 0 && row === 22))
           {
        // do nothing
      } else if (this.divider && cell.apply_divider) {
        value /= this.divider
        applied = true
      }
      return this.$numberFormat.format(value)
    },
    cellClick(row, col_num) {
      if (!this.canModifyMatrix) {
        return
      }

      const row_num = row.order
      const cell = this.getCell(row_num, col_num)
      if (!cell) {
        return
      }

      const selection_rows = JSON.parse(this.matrix.selection_rows)
      const selection_columns = JSON.parse(this.matrix.selection_columns)
      if ((selection_rows && !selection_rows.includes(row_num)) ||
          (selection_columns && !selection_columns.includes(col_num))) {
        return
      }

      if (this.matrix.selection === 1) {
        cell.is_selected = !cell.is_selected
      } else if (cell.is_selected) {
        return
      } else if (this.matrix.selection === 2) {
        for (let i = 0; i < selection_rows.length; i++) {
          const c = this.getCell(selection_rows[i], col_num)
          if (c) {
            c.is_selected = selection_rows[i] === row_num
          }
        }
      } else if (this.matrix.selection === 3) {
        for (let i = 0; i < selection_columns.length; i++) {
          const c = this.getCell(row_num, selection_columns[i])
          if (c) {
            c.is_selected = selection_columns[i] === col_num
          }
        }
      } else {
        return
      }

      this.selectCell(cell.id)
    },
    rowClick(row) {
      if (this.canModifyMatrix && row?.is_clickable) {
        this.selectRow(row.id)
      }
    },
    columnClick(column) {
      if (this.canModifyMatrix && column?.is_clickable) {
        this.selectColumn(column.id)
      }
    },
    changeValue(input, row, col) {
      if (!this.canModifyMatrix) {
        return
      }

      const cell = this.getCell(row, col)
      if (!cell) {
        return
      }

      let value = input.value.replaceAll(',', '.').trim()
      if (!isNaN(parseFloat(value)) && !isNaN(value - 0)) {
        value = parseFloat(value)
        if (cell.apply_divider) {
          value *= this.divider
        }
        if (value !== parseFloat(cell.value)) {
          cell.value = value
          this.updateCell(cell.id, value)
        }
      }

      input.value = this.getValue(row, col)
    },
    selectCell(id) {
      if (!this.canModifyMatrix) {
        return
      }
      this.loading = true
      console.log(this.loading)

      this.$http
          .post(`/cells/${id}/select`)
          .then(response => {
            console.log('Matrix->selectCell(): selection is changed')
            this.emitReloadMatrixCells(response.data.ids)
          })
          .catch(error => {
            console.error('Matrix->selectCell(): ERROR: ' + error)
            this.emitReloadMatrixCells([this.matrix.id])
          })
    },
    selectRow(id) {
      if (!this.canModifyMatrix) {
        return
      }
      this.loading = true
      this.$http
          .post(`/rows/${id}/select`)
          .then(response => {
            console.log('Matrix->selectRow(): selection is changed')
            this.emitReloadMatrixCells(response.data.ids)
          })
          .catch(error => {
            console.error('Matrix->selectRow(): ERROR: ' + error)
            this.emitReloadMatrixCells([this.matrix.id])
          })
    },
    selectColumn(id) {
      if (!this.canModifyMatrix) {
        return
      }
      this.loading = true
      this.$http
          .post(`/columns/${id}/select`)
          .then(response => {
            console.log('Matrix->selectColumn(): selection is changed')
            this.emitReloadMatrixCells(response.data.ids)
          })
          .catch(error => {
            console.error('Matrix->selectColumn(): ERROR: ' + error)
            this.emitReloadMatrixCells([this.matrix.id])
          })
    },
    updateCell(id, value) {
      if (!this.canModifyMatrix) {
        return
      }
      this.loading = true
      console.log(this.loading)


      this.$http
          .post(`/cells/${id}/update`, {'value': value})
          .then(response => {
            console.log('Matrix->updateCell(): cell value is changed')
            this.emitReloadMatrixCells(response.data.ids)
          })
          .catch(error => {
            console.error('Matrix->updateCell(): ERROR: ' + error)
            this.emitReloadMatrixCells([this.matrix.id])
          })
    },
    emitReloadMatrixCells(ids) {
      this.$emit('reloadMatrixCells', ids)
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
}

tr.data-row:nth-child(odd) {
  background-color: #d5e2ef;
}

th {
  padding: 0.2rem 0.3rem;
  border: 1px solid #aaa;
  font-size: 0.9rem;
  overflow-wrap: anywhere;
  color: #0074B5;
}

td {
  padding: 0.2rem 0.2rem 0.2rem 0.1rem;
  border: 1px solid #aaa;
  text-align: right;
  font-size: 0.8rem;
  overflow-wrap: anywhere;
}

td.period {
  text-align: center;
  min-width: 8rem;
  max-width: 8rem;
}

td.bold {
  font-weight: bold;
}

td.selected {
  background-color: darkgray;
}

td.editable {
  padding: 0.2rem;
}

.width-1 {
  min-width: 7.4rem;
  max-width: 7.4rem;
}

.width-1000 {
  min-width: 5.8rem;
  max-width: 5.8rem;
}

.width-1000000 {
  min-width: 4.2rem;
  max-width: 4.2rem;
}

.blue td.selected {
  background-color: #0074B5;
}

.blue td.selected span {
  color: #ffffff;
}

th.width-1000 {
  font-size: 0.7rem;
}

th.width-1000000 {
  font-size: 0.6rem;
}

.res-matrix-2 th.width-1 {
  min-width: calc(7.4rem * 2);
  max-width: calc(7.4rem * 2);
}

.res-matrix-2 th.width-1:first-child {
  min-width: calc(8rem + 7.4rem * 4);
  max-width: calc(8rem + 7.4rem * 4);
}

.res-matrix-2 th.width-1000 {
  min-width: calc(5.8rem * 2);
  max-width: calc(5.8rem * 2);
}

.res-matrix-2 th.width-1000:first-child {
  min-width: calc(8rem + 5.8rem * 4);
  max-width: calc(8rem + 5.8rem * 4);
}

.res-matrix-2 th.width-1000000 {
  min-width: calc(4.2rem * 2);
  max-width: calc(4.2rem * 2);
}

.res-matrix-2 th.width-1000000:first-child {
  min-width: calc(8rem + 4.2rem * 4);
  max-width: calc(8rem + 4.2rem * 4);
}

td input {
  width: 100%;
  font-size: 0.8rem;
  border: none;
  outline: 0;
  text-align: right;
  padding: 0.1rem;
}

td input:focus {
  border: none;
  outline: 0;
}

td span {
  font-size: 0.8rem;
}

.charts {
  display: flex;
}

.slot-0 {
  background-color: #3cb371 !important;
}

.slot-1 {
  background-color: #77dd77 !important;
}

.slot-2 {
  background-color: #98fb98 !important;
}

.slot-3 {
  background-color: #fffacd !important;
}

.slot-4 {
  background-color: #ffe4b5 !important;
}

.slot-5 {
  background-color: #ffa07a !important;
}

.slot-6 {
  background-color: #fa8072 !important;
}

.slot-0 span, .slot-1 span, .slot-2 span, .slot-3 span, .slot-4 span, .slot-5 span, .slot-6 span {
  color: black !important;
}

.loading{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.loading img{
  width: 3rem;
}

/* Overflow для больших таблиц */
.matrix{
  position: relative;
  min-width: 100%;
  width: 1000px;
  overflow-x: auto;
}

html {
  overflow-x: hidden;
}

.matrix-small {
    height: 500px;
}
</style>
