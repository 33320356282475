<template>
  <div class="menu">
    <div @click="onLinkClick('review')" :class="activeCategory === 'review' ? 'link active' : 'link'">
      Краткий обзор функционала
    </div>
    <div @click="onLinkClick('estimates')" :class="activeCategory === 'estimates' ? 'link active' : 'link'">
      Наилучшие Оценки
    </div>
    <div @click="onLinkClick('rp')" :class="activeCategory === 'rp' ? 'link active' : 'link'">
      Рисковые Поправки (РП)
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'GuideMenu',
  props: {
    categoryTitle: String,
    activeCategory: String
  },
  methods: {
    onLinkClick(categoryTitle) {
      this.$emit('clicked', categoryTitle)
    }
  },
}
</script>

<style scoped>
.menu{
  display: flex;
  flex-direction: column;

  width: 20%;
  height: 100%;

  background-color: rgba(128, 128, 128, 0.295);
}

.link{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;

  color: black;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;

  padding: 1rem;

  border: 1px solid black;
}

.link.active{
  background-color: #0074b5;
  color: white;
}
</style>
