<template>
  <div class="tab-menu">
    <div 
      v-for="section in sections" 
      @click="onMenuItemClick(section.id)" 
      :class="activeSection === section.id ? 'item active' : 'item'"
    >
      {{ section.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: Array,
    activeSection: String,
  },
  name: 'TabMenu',
  methods: {
    onMenuItemClick(section){
      this.$emit('onSectionSwitch', section)
    } 
  }
}
</script>

<style scoped>
.tab-menu{
  display: flex;
  justify-content: space-around;
}

.item{
  padding: 10px;
  border: 1px solid black;
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: all 0.5s;
  background-color: white;
}

.item.active{
  background-color: #531a56;
  color: white;
}
</style>
