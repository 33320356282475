<template>
	<div class="filter">
		<Multiselect :choices="groups" :messageOnEmpty="'Выберите портфели'" @onChange="onGroupChange"/>
		<Multiselect :choices="dates" :messageOnEmpty="'Выберите отчетные даты'" @onChange="onDateChange"/>
	</div>
</template>

<script>
import Multiselect from "@/components/Analytics/Multiselect";

export default {
	name: 'AnalyticsFilter',
	components: {
    Multiselect
  },
	props: {
		dates: Array,
		groups: Array,
  },
	methods: {
		onGroupChange(data){
			this.$emit('onGroupChange', data)
		},
		onDateChange(data){
			this.$emit('onDateChange', data)
		}
	}
}
</script>

<style scoped>
.filter{
	display: flex;
	align-items: center;
	gap: 20px;
}
</style>
	