<template>
  <Top :show-user="true"/>
  <div class="analytics" v-if="Object.keys(directData).length > 0">
    <TabMenu :sections='sections' :activeSection='activeSection' @onSectionSwitch="setActiveSection"/>
    <div v-show="activeSection === 'direct'" class="section">
      <AnalyticsDataBlock 
        :link='"/export/direct-fact/analytics"' 
        :groups="directData.groups" 
        :dates="directData.reportDates"
        :title="'Прямой - фактические'"
        :activeSection="activeSection"
      />
      <AnalyticsDataBlock 
        :link='"/export/direct-expected/analytics"' 
        :groups="directData.groups" 
        :dates="directData.reportDates"
        :title="'Прямой - ожидания'"
        :multiDate="true"
      />
    </div>
    <div v-show="activeSection === 'reinsurance'" class="section">
      <AnalyticsDataBlock 
        :link='"/export/reinsurance-fact/analytics"' 
        :groups="reinsuranceData.groups" 
        :dates="reinsuranceData.reportDates"
        :title="'Перестрахование - фактические'"

      />
      <AnalyticsDataBlock 
        :link='"/export/reinsurance-expected/analytics"' 
        :groups="reinsuranceData.groups" 
        :dates="reinsuranceData.reportDates"
        :title="'Перестрахование - ожидания'"
        :multiDate="true"
      />
    </div>
  </div>
  <div class="loading" v-else>
    <img src="@/assets/img/loading.svg" alt="" srcset="">
  </div>
</template>

<script>
import Top from "@/components/Top";
import TabMenu from "@/components/Analytics/TabMenu";
import AnalyticsDataBlock from "@/components/Analytics/AnalyticsDataBlock";

export default {
  name: 'ExportAnalytics',
  components: {
    Top,
    TabMenu,
    AnalyticsDataBlock
  },
  data() {
    return {
      sections: [
        {id: 'direct', name: 'Прямой бизнес'},
        {id: 'reinsurance', name: 'Перестрахование'},
      ],
      activeSection: 'direct',
      directData: Object, 
      reinsuranceData: Object,
    }
  },
  methods: {
    getSettingsData(){
    this.$http
      .get(`/settings`)
      .then(response => {        
        this.directGroups = response.data.groups.filter(i => i.type === 300).map(group => group.id)
        this.reinsuranceGroups = response.data.groups.filter(i => i.type === 301).map(group => group.id)

        console.log('Groups->loaded(): done')
      })
      .catch(error => console.error('Groups->loaded(): ERROR: ' + error))
    },
    loadReports() {
      this.$http
        .get('/reports')
        .then(response => {
          this.itemsDirect = response.data.filter(i => (i.type !== 105) && (this.directGroups.includes(i.group)))
          this.itemsReinsurance = response.data.filter(i => (i.type !== 105) && (this.reinsuranceGroups.includes(i.group)))
          this.directData = this.getData(this.itemsDirect)
          this.reinsuranceData = this.getData(this.itemsReinsurance)
          console.log('loadReports: data is loaded')
        })
        .catch(error => console.error('loadReports: ERROR: ' + error))
    },
    getData(items){
      const data = {}
      data.reportDates = [...new Set(items.map(report => {
        return report.report_date
      }))]
      data.groups = items.map(report => {
        return {id: report.group, name: report.group_name}
      })
      data.groups = [...new Map(data.groups.map(item => [item['id'], item])).values()]
      return data
    },
    setActiveSection(section){
      this.activeSection = section
    }
  },
  mounted(){
    this.loadReports(),
    this.getSettingsData()
  },
}
</script>

<style scoped>
.analytics{
  margin-top: 5.2rem;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loading{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.loading img{
  width: 3rem;
}

.section{
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
