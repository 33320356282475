<template>
  <Top :show-user="true"/>
  <div class="container">
    <div class="main-title">
      <h1>Руководство пользователя ПО «Actool»</h1>
    </div>
    <div class="content">
      <GuideMenu @clicked="switchCategory" :activeCategory="activeCategory"/>
      <GuideSection v-if="activeCategory == 'review'">
        <QuickGuide />
      </GuideSection>
      <GuideSection v-else-if="activeCategory == 'estimates'">
      </GuideSection>
      <GuideSection v-else-if="activeCategory == 'rp'">
      </GuideSection>
    </div>
  </div>
</template>
  
<script>
import Top from "@/components/Top";
import GuideMenu from "@/components/Guide/GuideMenu";
import GuideSection from "@/components/Guide/GuideSection"
import QuickGuide from "@/components/Guide/Sections/QuickGuide"

export default {
  name: 'Guide',
  components: {
    Top,
    GuideMenu,
    GuideSection,
    QuickGuide
  },
  data() {
    return {
      activeCategory: 'review',
    }
  },
  methods:{
    switchCategory(category) {
      this.activeCategory = category;
    },
  }
}
</script>

<style scoped>
.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 5.2rem);
  
  margin-top: 5.2rem;
  padding: 1rem 0rem 0rem 0rem;

  overflow: hidden;
}

h1{
  color: #0074b5;
  font-weight: bold;
  font-size: 2rem;

  padding: 0px 1rem;
}

.content{
  display: flex;
  width: 100%;
  height: 100%;

  padding: 1rem 0 0 0;
}
</style>
