<template>
  <div class="container">
    <Top :show-user="true"/>
    <div class="body">
      <div class="block">
        <div class="first">
          <img src="@/assets/img/vault.svg" alt="">
          <router-link to="/dashboard" test-id="calculations_link">Свод по расчетам</router-link>
          <img src="@/assets/img/indicator_blue.svg" alt="">
        </div>
        <div class="second">
          <div class="header">
            <router-link to="/dashboard">Расчеты за закрытые периоды</router-link>
            <img src="@/assets/img/indicator_blue.svg" alt="">
          </div>
          <div class="content manual">
            <ul>
              <router-link v-for="date in confirmedDates" :to="getUrl(date)" :key="date" class='confirmed-list'>
                <li class="confirmed-list"> {{ date }} </li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>

      <div class="block">
        <div class="first">
          <img src="@/assets/img/evaluations.svg" alt="">
          <router-link to="/reports/101" test-id="grades_link">Наилучшие оценки</router-link>
          <img src="@/assets/img/indicator_blue.svg" alt="">
        </div>
        <div class="second">
          <div class="header">
            <router-link to="/dashboard">Последние расчеты</router-link>
            <img src="@/assets/img/indicator_blue.svg" alt="">
          </div>
          <div class="content manual">
          </div>
        </div>
      </div>

      <div class="block">
        <div class="first">
          <img src="@/assets/img/risky.svg" alt="">
          <router-link to="/reports/102" test-id="risk_link">Рисковые поправки</router-link>
          <img src="@/assets/img/indicator_blue.svg" alt="">
        </div>
        <div class="second">
          <div class="header">
            <router-link to="/guide">Инструкция пользователя</router-link>
            <img src="@/assets/img/indicator_blue.svg" alt="">
          </div>
          <div class="content manual">
          </div>
        </div>
      </div>

      <div class="block">
        <div class="first">
          <img src="@/assets/img/cash.svg" alt="">
          <router-link to="/reports/103" test-id="flow_link">Денежные потоки</router-link>
          <img src="@/assets/img/indicator_blue.svg" alt="">
        </div>
        <div class="second">
          <div class="header">
            <router-link to="/about">О продукте</router-link>
            <img src="@/assets/img/indicator_blue.svg" alt="">
          </div>
          <div class="content product">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top";

export default {
  name: 'Main',
  components: {
    Top
  },
  data() {
    return {
      confirmedDates: [],
    }
  },
  methods: {
    getConfirmedReportDates(){
      this.$http
          .get('filter-reports/')
          .then(response => {
            this.confirmedDates = response.data.dates
          })
          .catch(error => console.error('Dashboard->mounted(): ERROR: '))
    },
    getUrl(date) {
      return '/dashboard/' + '?report-date='+ date
    }
  },
  mounted() {
    this.getConfirmedReportDates()
  },
}
</script>

<style scoped>
.body {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  background-image: url('@/assets/img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
}

.block {
  padding: 0 0.8rem 0 0.8rem;
  margin-top: 5.2rem;
}

.first {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 0.5rem;
  background-color: #ffffff;
  width: 16rem;
  height: 3rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.first a {
  display: block;
  width: 100%;
  margin-left: 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #0074b5;
}

.second {
  margin-top: 3rem;
  padding: 1rem 1rem 1rem 0.5rem;
  background-color: #ffffff;
  width: 16rem;
  height: 12rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.header {
  display: flex;
  align-items: flex-start;
  height: 3rem;
}

.header a {
  display: block;
  width: 100%;
  margin-left: 0.5rem;
  text-decoration: none;
  color: #0074b5;
}

.content {
  height: 7rem;
  padding: 0.5rem;
}

.content ul {
  margin-left: 1rem;
}

.content li {
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}

.manual {
  background-image: url('@/assets/img/manual.svg');
  background-repeat: no-repeat;
  background-position: right bottom -10px;
}

.product {
  background-image: url('@/assets/img/product.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
}

.confirmed-list{
  text-decoration: none;
  color: #0074b5; 
  font-weight: bold;
}

</style>
