import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from '@/components/Dashboard/Dashboard'
import Report from '@/components/Report/Report'
import Upload from '@/components/Upload/Upload'
import Page404 from '@/components/Error/Page404'
import Login from "@/components/User/Login";
import Main from "@/components/Main/Main";
import OneCalc from "@/components/Report/OneCalc.vue";
import Settings from "@/components/Settings/Settings.vue";
import Profile from "@/components/Profile/Profile.vue";
import About from "@/components/About.vue";
import ExportAnalytics from "@/components/Analytics/ExportAnalytics.vue";
import Guide from '@/components/Guide/Guide.vue';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'main',
        component: Main
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/reports/101',
        name: 'reports-101',
        component: Report
    },
    {
        path: '/reports/102',
        name: 'reports-102',
        component: Report
    },
    {
        path: '/reports/103',
        name: 'reports-103',
        component: Report
    },
    {
        path: '/reports/115',
        name: 'reports-115',
        component: Report
    },
    {
        path: '/reports/199',
        name: 'reports-199',
        component: Report
    },
    {
        path: '/reports/:group/:type(101)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-101',
        component: Report
    },
    {
        path: '/reports/:group/:type(102)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-102',
        component: Report
    },
    {
        path: '/reports/:group/:type(103)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-103',
        component: Report
    },
    {
        path: '/reports/:group/:type(104)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-104',
        component: Report
    },
    {
        path: '/reports/:group/:type(105)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-105',
        component: Report
    },
    {
        path: '/reports/:group/:type(106)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-106',
        component: Report
    },
    {
        path: '/reports/:group/:type(107)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-107',
        component: Report
    },
    {
        path: '/reports/:group/:type(108)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-108',
        component: Report
    },
    {
        path: '/reports/:group/:type(109)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-109',
        component: Report
    },
    {
        path: '/reports/:group/:type(110)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-110',
        component: Report
    },
    {
        path: '/reports/:group/:type(111)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-111',
        component: Report
    },
    {
        path: '/reports/:group/:type(112)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-112',
        component: Report
    },
    {
        path: '/reports/:group/:type(113)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-113',
        component: Report
    },
    {
        path: '/reports/:group/:type(114)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-114',
        component: Report
    },
    {
        path: '/reports/:group/:type(116)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-116',
        component: Report
    },
    {
        path: '/reports/:group/:type(199)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-199',
        component: Report
    },
    {
        path: '/reports/:group/:type(198)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-198',
        component: Report
    },
    {
        path: '/reports/:group/:type(117)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-117',
        component: Report
    },
    {
        path: '/reports/:group/:type(120)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-120',
        component: Report
    },
    {
        path: '/reports/:group/:type(121)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-121',
        component: Report
    },
    {
        path: '/reports/:group/:type(122)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-122',
        component: Report
    },
    {
        path: '/reports/:group/:type(123)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-123',
        component: Report
    },
    {
        path: '/reports/:group/:type(124)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-124',
        component: Report
    },
    {
        path: '/reports/:group/:type(125)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-125',
        component: Report
    },
    {
        path: '/reports/:group/:type(126)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-126',
        component: Report
    },
    {
        path: '/reports/:group/:type(127)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-127',
        component: Report
    },
    {
        path: '/reports/:group/:type(128)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-128',
        component: Report
    },
    {
        path: '/reports/:group/:type(129)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-129',
        component: Report
    },
    {
        path: '/reports/:group/:type(130)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-130',
        component: Report
    },
    {
        path: '/reports/:group/:type(118)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-118',
        component: Report
    },
    {
        path: '/reports/:group/:type(119)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-119',
        component: Report
    },
    {
        path: '/reports/:group/:type(131)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-131',
        component: Report
    },
    {
        path: '/reports/:group/:type(132)/:date(\\d{4}-\\d{2}-\\d{2})',
        name: 'report-132',
        component: Report
    },
    {
        path: '/calculations/:id(\\d+)',
        name: 'calc-101',
        component: OneCalc
    },
    {
        path: '/upload',
        name: 'upload',
        component: Upload
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings
    },
    {
        path: '/:path(.*)*',
        name: 'page404',
        component: Page404
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/export-analytics',
        name: 'ExportAnalytics',
        component: ExportAnalytics
    },
    {
        path: '/guide',
        name: 'Guide',
        component: Guide
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        next()
    } else if (window.drfUser && window.drfUser.id) {
        next()
    } else {
        next({name: 'login'})
    }
})

export default router
