<template>
  <div class="container">
    <div :class="'line title' + isActive(types)">
      <router-link :to="link">
        {{ title }}
      </router-link>
      <img src="@/assets/img/indicator_blue.svg" alt="">
    </div>

    <div v-if="isActive(types) && items?.length">
      <div class="line subtitle">
        <div>
          Расчеты
        </div>
      </div>
      <div v-for="item in items" :key="item.order" class="line">
        <a @click="$emit('scrollToCalc', item.id)">
          {{ item.name }}
        </a>
        <img src="@/assets/img/indicator_blue.svg" alt="">
      </div>

      <template v-if="(types.includes(101) || types.includes(102) || types.includes(115) 
                      || types.includes(120) || types.includes(121) || types.includes(122) || types.includes(123)
                      || types.includes(124) || types.includes(125) || types.includes(126)) && canModify">
        <div class="line">
          <a @click="showParams = !showParams">
            Добавить
          </a>
          <div v-if="!showParams">
            +
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div v-if="showParams" class="params">
          <div class="param">
            <div class="name">
              Название
            </div>
            <div>
              <input type="text" ref="name">
            </div>
          </div>
          <div class="param">
            <div class="name">
              Версия
            </div>
            <div>
              <select ref="version">
                <option value="" selected>-</option>
                <option v-for="v in versions" :value="v.id + ':' + v.report_id" :key="v.id">{{ v.version }}</option>
              </select>
            </div>
          </div>
          <div class="param">
            <div class="name">
              Метод расчета
            </div>
            <div>
              <select ref="method">
                <option value="" selected>-</option>
                <template v-if="type === 101">
                  <option value="101">ЦЛ</option>
                  <option value="102">БФ</option>
                  <option value="103">Частота и среднее</option>
                  <option value="104">Простая убыточность</option>
                </template>
                <template v-if="type === 120">
                  <option value="101">ЦЛ</option>
                  <option value="102">БФ</option>
                  <option value="601">Доля от резерва</option>
                </template>
                <template v-else-if="type === 102">
                  <option value="201">Bootstrap</option>
                </template>
                <template v-else-if="[106, 107, 108].includes(type)">
                  <option value="101">ЦЛ</option>
                  <option value="102">БФ</option>
                  <option value="601">Доля от резерва</option>
                  <option value="602">По среднему РУУ</option>
                </template>
                <template v-else-if="[112, 113].includes(type)">
                  <option value="101">ЦЛ</option>
                  <option value="102">БФ</option>
                  <option value="104">Простая убыточность</option>
                </template>
                <template v-else-if="[109, 110].includes(type)">
                  <option value="101">ЦЛ</option>
                  <option value="102">БФ</option>
                  <option value="104">Простая убыточность</option>
                  <option value="601">Доля от резерва</option>
                </template>
                <template v-else-if="type === 111">
                  <option value="601">Доля от резерва</option>
                  <option value="602">По среднему РУУ</option>
                </template>
                <template v-else-if="[121, 122, 123, 124, 125, 126].includes(type)">
                  <option value="601">Доля от резерва</option>
                </template>
                <template v-else-if="type === 114">
                  <option value="1401">На основании волатильности по произошедшим убыткам</option>
                  <option value="1402">Мака по произошедшим убыткам</option>
                  <option value="1403">На основании волатильности андеррайтинговый</option>
                  <option value="1404">Мака андеррайтинговый</option>
                </template>
              </select>
            </div>
          </div>
          <div class="param add">
            <a @click="addCalc">Создать расчет</a>
          </div>
        </div>
      </template>
    </div>
  </div>
  <teleport to="body">
    <Modal :show="showErrorModal" :controlsAvaliable="true">
      <template #header>
        <div class="modal-header">Возникла ошибка!</div>
      </template>
      <template #body>
        Невозможно посчитать этот метод, т.к. не посчитан метод 'Частота и среднее' в Резерве убытках
      </template>
      <template #footer>
        <button @click="switchErrorModalState">Ок</button>
      </template>
    </Modal>
  </teleport>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'LeftTypeSelectorItem',
  components: {
    Modal
  },
  props: {
    type: Number,
    types: Array,
    canModify: Boolean,
    title: String,
    link: String,
    items: Array,
    versions: Array,
    report: Object,
  },
  data() {
    return {
      showParams: false,
      showErrorModal: false
    }
  },
  watch: {
    $route() {
    }
  },
  emits: ['scrollToCalc', 'addCalc'],
  methods: {
    switchErrorModalState(){
        this.showErrorModal = !this.showErrorModal
    },
    isActive(types) {
      const param = Number(this.$route.name.substr(-3))
      return types.includes(param) || !(types.length || param) ? ' active' : ''
    },
    addCalc() {
      const name = this.$refs.name.value.trim()
      if (!name) {
        return
      }

      let version = this.$refs.version.value
      if (!version) {
        return
      }
      version = version.split(':')

      const method = this.$refs.method.value
      if (!method) {
        return
      }
      
      const fa_names = Object.keys(this.report.ru_fa_names)
      if (fa_names.length === 0 && Number(method) === 602) {
        this.switchErrorModalState()
        return
      }

      this.showParams = false
      this.$emit('addCalc', {'id': version[0], 'report_id': version[1], 'name': name, 'method': method})
    }
  }
}
</script>

<style scoped>
.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0.5rem 0.7rem 1rem;
}

.line > a {
  display: block;
  text-decoration: none;
  margin-right: 0.5rem;
  color: #707070;
  cursor: pointer;
  max-width: 12.5rem;
  overflow: clip;
}

.params {
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  margin: 0.2rem 0.3rem;
  padding: 0.5rem;
}

.param {
  margin-bottom: 0.5rem;
}

.param .name {
  font-size: 0.9rem;
  padding-bottom: 0.2rem;
}

.param input {
  font-size: 0.9rem;
  padding: 0.1rem 0.2rem;
  width: 100%;
}

.param select {
  width: 100%;
}

.param.add {
  display: flex;
  justify-content: center;
}

.param a {
  cursor: pointer;
  color: #3ebaff;
}

.title {
  text-transform: uppercase;
}

.title > a {
  color: #cbd2e1;
}

.subtitle {
  font-weight: bold;
}

.active {
  background-color: #efeeee;
}

.active > a {
  color: #0074b5;
}

.active > img {
  transform: rotate(90deg);
}
</style>
