<template>
  <Modal :show="show" @ok="exportCashflows" @cancel="switchExportModalState" :controlsAvaliable="true">
    <template #header>
      <div class="modal-header">Выгрузка потоков</div>
    </template>
    <template #body>
      <div class="form" v-if="!showSuccessMesage">
        <div class="filter">
          <label class="pax-title">Источник потоков</label>
          <select v-model="exportSource">
            <option value="all">Все</option>
            <option value="direct">Прямой бизнес</option>
            <option value="reinsurance">Перестрахование</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Тип потоков</label>
          <select v-model="exportType" :class=lockFilters>
            <option value="year">Годовые</option>
            <option value="month">Месячные</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Резервная группа</label>
          <select v-model="exportGroup">
            <option :value='1000'>Все</option>
            <option v-if="exportSource === 'direct'" :value='group.id' v-for="group in reportGroupsDirect" :key="group.id" :class="lockFilters">{{ group.name }}</option>
            <option v-else-if="exportSource === 'reinsurance'" :value='group.id' v-for="group in reportGroupsReinsurance" :key="'re' + group.id" :class="lockFilters">{{ group.name }}</option>
          </select>
        </div>
        <div class="filter" v-if="exportType === 'month' && (exportSource === 'direct' || exportSource === 'all')">
          <label class="pax-title">Тип обременительности (прямой бизнес)</label>
          <select v-model="exportOnerousType" :disabled="lockFilters">
            <option value="1">Onerous</option>
            <option value="2">Non-onerous</option>
            <option value="3">Other</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Валюта</label>
          <select v-model="exportCurrency" :disabled="lockFilters">
            <option value="1">RUB</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Модель для оценки</label>
          <select v-model="exportModel" :disabled="lockFilters">
            <option value="1">GMM</option>
            <option value="2">PAA</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Отчетная дата</label>
          <select v-model="exportReportDate" :disabled="lockFilters">
            <option v-if="exportType === 'year'" :value="'2023-12-31'">2023-12-31</option>
            <option v-else-if="exportType === 'month' && (exportSource === 'direct' ||exportSource === 'all')" v-for="(date, direct_index) in reportDatesDirect" :key="direct_index" :value="date">{{ date }}</option>
            <option v-else-if="exportType === 'month' && exportSource === 'reinsurance'" v-for="(date, reinsurance_index) in reportDatesReinsurance" :key="reinsurance_index" :value="date">{{ date }}</option>
          </select>
        </div>
      </div>
      <div class="message" v-if="showSuccessMesage">
        <h3 class="pax-title">Выгрузка прошла успешно!</h3>
      </div>
      <div class="message" v-if="exportErrorMessage">
        <h3 class="pax-title">Ошибка: {{ exportErrorMessage }}</h3>
      </div>
    </template>
    <template #footer v-if="!inProgress && !showSuccessMesage">
    </template>
    <template #footer v-else-if="showSuccessMesage">
      <button class="modal-default-button" @click="switchSuccessMessageState">Закрыть</button>
    </template>
    <template #footer v-else>
      <h3 class="pax-title">Формируются потоки...</h3>
    </template>
  </Modal>
</template>
  
<script>
import Modal from "@/components/Modal.vue";
export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    start: Function,
    done: Function,
    inProgress: Boolean,
  },
  data() {
    return {
      showSuccessMesage: false,
      exportModel: '1',
      exportGroup: '1',
      exportReportDate: '2023-12-31',
      exportCurrency: '1',
      exportOnerousType: 1,
      exportErrorMessage: '',
      exportType: 'year',
      exportSource: 'direct',
      reportDatesDirect: [],
      reportDatesReinsurance: [],
      reportGroupsDirect: [],
      reportGroupsReinsurance: [],
    }
  },
  computed: {
    lockFilters() {
        if (this.exportGroup === 1000 && this.exportSource === 'all') {
          return 'locked-filter'
        }
    },
  },
  watch: {
    exportType(exportType){
      if(exportType === '1'){
        this.exportReportDate = '2023-12-31'
      } else {
        this.exportReportDate = '2024-01-31'
      }
    },
    exportSource: {
      handler(source) {
        if(source === 'direct'){
          this.exportGroup = this.reportGroupsDirect[0].id
        }else if(source === 'reinsurance'){
          this.exportGroup = this.reportGroupsReinsurance[0].id
        }else{
          this.exportGroup = 1000
        }
      },
      deep: true
    }
  },
  mounted() {
    this.loadGroupsData()
  },

  methods: {
    switchExportModalState() {
      this.$emit("switchExportModalState");
    },
    showErrorMessage(message) {
      this.exportErrorMessage = message; 
    },
    hideErrorMessage() {
      this.exportErrorMessage = ''; 
    },
    switchSuccessMessageState() {
      if(this.showSuccessMesage){
        this.hideErrorMessage();
        this.showSuccessMesage = false;
        this.switchExportModalState();
      }else{
        this.showSuccessMesage = true;
      }
    },
    loadGroupsData() {
      this.$http
        .get(`/settings`)
        .then(response => {
          this.groups = response.data.groups       
          console.log('Groups->loaded(): done')
          this.loadReportDatesData()
        })
        .catch(error => console.error('Groups->loaded(): ERROR: ' + error))
    },
    loadReportDatesData(){
      this.$http
        .get('/reports-direct')
        .then(response => {
          const result = response.data.filter(report => report.type === 101).map(report => {
            let copy = Object.assign({}, report)
            copy.type = 199
            copy.type_name = 'Обзор результата'
            return copy
          })
          const reports = response.data.concat(result)
          this.reportDatesDirect = [...new Set(reports.map(report => report.report_date))]
          this.reportGroupsDirect = this.groups.filter(group => group.type === 300) 
          console.log('Report->loadDirectReportDates(): done')
        })
        .catch(error => console.error(`Report->loadDirectReportDates(): ERROR: ${error}`))

      this.$http
        .get('/reports-reinsurance')
        .then(response => {
          const result = response.data.filter(report => report.type === 120).map(report => {
            let copy = Object.assign({}, report)
            copy.type = 199
            copy.type_name = 'Обзор результата'
            return copy
          })
          const reports = response.data.concat(result)
          this.reportDatesReinsurance = [...new Set(reports.map(report => report.report_date))]
          this.reportGroupsReinsurance = this.groups.filter(group => group.type === 301) 
          console.log('Report->loadReinsuranceReportDates(): done')
        })
        .catch(error => console.error(`Report->loadReinsuranceReportDates(): ERROR: ${error}`))
    },
    exportCashflows() {
      const exportLink = '/export/cashflows'
      this.hideErrorMessage();
      const exportFilterData = {
        'export_type': this.exportType,
        'export_source': this.exportSource,
        'group': this.exportGroup,
        'model': this.exportModel,
        'report_date': this.exportReportDate,
        'currency': this.exportCurrency,
        'onerous_type': this.exportOnerousType,
      }
      if (this.start()) {
        this.$http
          .post(exportLink, exportFilterData, { responseType: 'blob' })
          .then(response => {
            console.log(`Exporting Cashflows`)

            if(this.exportType === 'year'){
              const blob = new Blob([response.data], {type: response.data.type});
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = decodeURIComponent(response.headers.get('content-disposition').split('filename=')[1]);
              link.click();
            }
            
            this.switchSuccessMessageState();
          })
          .catch(error => {
            console.log('Error in cashflows export', error);
            error.response.data.text().then(text => {
              this.showErrorMessage(text);
            })
          })
          .finally(() => this.done())
      }
    }
  }
}
</script>
  
<style scoped>
.modal-header {
  font-weight: bold;
  color: #0074B5;
}

.modal-list-item {
  display: flex;
  align-items: center;
  padding-top: 0.3rem;
}

.modal-list-item input {
  margin-right: 0.5rem;
}

.filter{
  display: flex;
  justify-content: space-between;
}

.filter select {
  width: 9rem;
  margin-left: 2rem;
}

.filter{
  margin-bottom: 1rem;
}
</style>
  