<template> 
  <div>
    <p id="cash-flow-name" :class='userAlert()'> {{analytics.name}} </p>
    <table>
      <tr>
        <th>Название</th>
        <template v-if="multiDate">
          <th v-for="(date, index) in analytics.data.dates" :key="index">{{ date }}</th>
        </template>
        <template v-else>
          <th>Значение</th>
        </template>
      </tr>
      <template v-if="multiDate">
        <tr v-for="(record, index) in analytics.data.data" :key="index">
          <td>{{ record.name }}</td>
          <td v-for="(value, index) in record.values" :key="index">{{ getFormattedValue(value) }}</td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="(record, index) in analytics.data" :key="index">
          <td>{{ record.name }}</td> <td>{{ getFormattedValue(record.value) }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsTable',
  props: {
    analytics: Object,
    multiDate: Boolean
  },
  data(){
    return {
      numberFormat: new Intl.NumberFormat('us-US', {
        useGrouping: true, 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2
      })
    }
  },
  methods:{
    getFormattedValue(value){
      return this.numberFormat.format(value)
    },
    userAlert() {
      if (this.analytics.name === "ДЗ КЗ (на отчетную дату)") {
        return 'userAlert'
      }
    }
  }
}
</script>

<style scoped>
.analytics{
  margin-top: 5.2rem;
}

table{
  width: 100%;
  background-color: white;
  border:1px solid black;
}

tr td:last-child {
 width: 10%;
}

table, th, td {
  color: #6b6f77;
  border: 1px solid black;
  border-collapse: collapse;  
}

th, td {
  padding: 10px;
}

td{
  text-align: center;
}

td:first-child{
  width: 40%;
  text-align: center;
}

tr:nth-child(even){
  background-color: #d5e2ef;
}

#cash-flow-name{
  margin-bottom: 10px;
}

.userAlert {
  color:red;
}
</style>
  