<template>
	<div class="data-block">
    <div class='data-block-top'> 
      <h3>{{ title }}</h3>
      <div class="top-right-item">
        <img src="@/assets/img/download.svg" alt="">
        <a class="top-button" @click="downloadAnalytics" test-id="download_report">Скачать</a>
      </div>
    </div>
		<AnalyticsFilter 
			:dates="dates" 
			:groups="groupNames" 
			@onGroupChange="onGroupChange" 
			@onDateChange="onDateChange"
		/>
		<template v-if="analytics.length > 0">
			<AnalyticsTable :analytics="table" v-for="(table, key) in analytics" :key="key" :multiDate='multiDate'/>	
		</template>
		<template v-else>
			<h5>Данных нет</h5>
		</template>
	</div>
</template>

<script>
import AnalyticsTable from "@/components/Analytics/AnalyticsTable";
import AnalyticsFilter from "@/components/Analytics/AnalyticsFilter";

export default {
	name: 'AnalyticsDataBlock',
	components: {
    AnalyticsTable,
    AnalyticsFilter,
  },
  data() {
    return {
      analytics: [],
      activeReportDates: [],
      activeGroups: [],
      activeGroupName: [],
    }
  },
  props: {
    title: String,
    link: String,
    dates: Array,
    groups: Array,
    multiDate: Boolean, 
  },
  mounted(){
    this.activeReportDates.push(Object.values(this.dates)[0])
    this.activeGroups.push(this.groups[0].id)
  },
  computed: {
    groupNames() {
      return this.groups.map(group => group.name)
    }
  },
  methods: {
    getAnalyticsData(){
      this.$http
        .get(`${this.link}?forecast_date=${this.activeReportDates}&group=${this.activeGroup}`)
        .then(response => {
          if(response.status === 200){
            this.analytics = response.data.data
          }else{
            this.analytics = []
          }
        })
        .catch(()=>{
          this.analytics = []
        })
    },
    onGroupChange(data){
      this.activeGroup = this.groups.filter(group => data.includes(group.name)).map(group => group.id)
      this.activeGroupName = this.groups.filter(group => data.includes(group.name)).map(group => group.name)
      this.getAnalyticsData()
    },
    onDateChange(data){
      this.activeReportDates = this.dates.filter(date => data.includes(date)).map(date => date)
      this.getAnalyticsData()
    },
    downloadAnalytics() {
      let cfType = this.cashFlowType(this.link)
      this.$http({url: '/export/analytics', method: 'POST', data: [this.analytics, this.activeReportDates, this.activeGroupName, cfType], responseType: 'blob'})
      .then(response => {
        const blob = new Blob([response.data], {type: response.data.type});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob)
        link.download = "export_analytics.xlsx";
        link.click();

        console.log('Report->downloadAnalytics(): done')
      })
      .catch(error => console.error(`Report->downloadAnalytics(): ERROR: ${error}`))
    },
    cashFlowType(link){
      if (link.includes('direct-fact') || link.includes('reinsurance-fact')) {
        return 'fact'
      } else {
        return 'expected'
      }
    }
    
  },
}
</script>

<style scoped>
.data-block{
  width: fit-content;
	display: flex;
	flex-direction: column;
	gap: 20px;
	border: 1px solid black;
	padding: 20px;
  background-color: white;
}

.data-block-top{
  display: flex;
  justify-content: space-between;
}

.top-right-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.top-right-item:first-child {
  border-left: none;
}

.top-right-item > a {
  padding-left: 0.5rem;
  cursor: pointer;
  color: #3ebaff;
}

</style>
	